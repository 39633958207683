import React, { createContext, useContext, useReducer, useState } from 'react';
import { RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
}

export const StateContext = createContext<StateContextType>(null!);

const resolveEndpoint = () => {
  const host = window && window.location && window.location.host;
  // todo: add more cases when it's needed
  if (!host || /^localhost/.test(host)) {
    return process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
  }
  const apiHost = host.replace('app', 'api');
  return `https://${apiHost}/v1/roomservice/`;
};

const getRoomToken = async (identity: string, roomName: string) => {
  const handleErrors = (response: any) => {
    if (response.status === 403) {
      return response.json().then((result: any) => {
        if (result.message.includes('expired')) {
          throw Error('Your session expired. Please open the video call application again.');
        }
        throw Error('Please try again later.');
      });
    } else if (!response.ok) {
      throw Error('Please try again later.');
    }
    return response;
  };
  const headers = new window.Headers();
  const endpoint = resolveEndpoint();
  const rnd = Math.random() + '';
  const params =
    process.env.REACT_APP_USE_RANDOM === 'true'
      ? new window.URLSearchParams({ identity, roomName, rnd })
      : new window.URLSearchParams({ identity, roomName });

  if (process.env.REACT_APP_USE_RANDOM === 'true') {
    return fetch(`${endpoint}call`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: '',
        Authorization: `Bearer ${roomName}`,
      },
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(result => {
        const w: any = window;
        w.roomSid = result.roomId;
        return result.accessId;
      });
  } else {
    return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
  }
};
/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: getRoomToken,
    };
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
